import '../../vendors/bootstrap/dist/css/bootstrap.css';

import {withRedirects} from '@/lib/withRedirects';
import Bugsnag from '@bugsnag/js';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ArtistCommission from '../../src/components/artist-commission/artist-commission';
import ArtistContactMe from '../../src/components/artist-contact-me/artist-contact-me';
import Artist from '../../src/components/artist/artist';
import ProductCard from '../../src/components/cards/product-card/product-card';
import DropdownSelect from '../../src/components/dropdown-select/dropdown-select';
import FilterSection from '../../src/components/filter-section/filter-section';
import Bell from '../../src/components/icons-af/bell';
import Loading from '../../src/components/loading/loading';
import MasonryGallery from '../../src/components/masonry-gallery/masonry-gallery';
import Pagination from '../../src/components/pagination/pagination';
import Subnav from '../../src/components/subnav/subnav';
import {H1, H2} from '../../src/components/typography/typography';

import _ from '@/utils/_';
import constantsFactory from '@/utils/constants';
import {emitFactory} from '@/utils/events';
import {
  data as gtmData,
  historyChangedEvent,
  historyNewPagesChangedEvent,
  pushGAViewItemListEvent,
} from '@/utils/gtm';

import {queryEditorsPick} from '@/database/editors-pick';

import {trackPage} from '@/services/analytics-service';
import {get} from '@/services/artfinder-service';
import cacheStore from '@/services/cache-service';

import useAuth from '@/hooks/use-auth/use-auth';
import useCookie from '@/hooks/use-cookie/use-cookie';
import usePersistBasketStatus from '@/hooks/use-persist-basket-status/use-persist-basket-status';

import galleryActions from '@/redux/actions/galleryActions';
import {getCustomerFavourites, setArtistId} from '@/redux/actions/productActions';
import {impressions, pageView} from '@/redux/actions/trackingActions';
import {selectUserSettingsData} from '@/redux/reducers/userSettingsReducer';
import {initializeStore} from '@/redux/store';

import CtaButton from '@/atoms/Buttons/CTA/CtaButton';
import PageLayout from '@/organisms/Layout/Page/PageLayout';
import {getAppMetadata} from '@/utils/_app/getAppMetadata';
import './artist.scss';

const LIMIT = 12;
const ON_SALE = 'in_sale';

const optionName = (x) => `${x.label} (${x.buckets.true})`;

// todo: It would have been better if the options were an array (options: [a, b, ...])
const makeOptions = _.pipe(
  Object.values,
  _.filter(_.pipe(_.prop('field_type'), _.equals('boolean'))),
);

const collectionParams = (model, selectedValue) => {
  // All artworks object has the value undefined
  if (_.isNil(selectedValue)) {
    return {};
  }
  return {
    [model]: selectedValue,
  };
};

const optionsParams = (xs) => xs.reduce((a, x) => _.assoc(x, true, a), {});

const trim = (x) => x.trim().replace(/\/+$/, '');

const prefix = (artistSlug) => `/artist/${artistSlug}/`;

const DEFAULT_PAGE = 1;
const DEFAULT_SORT = 'artist_order'; // <==> sort.default
const DEFAULT_COLLECTION = null;
const DEFAULT_OPTIONS = [];
const COLLECTIONS_MODEL = 'collections'; // <==> collections.model

const params = ({selectedCollection, selectedOptions, selectedSort, page}) =>
  Object.assign(
    collectionParams(COLLECTIONS_MODEL, selectedCollection),
    optionsParams(selectedOptions),
    {sort: selectedSort},
    {page: page},
    {limit: LIMIT},
    {time: new Date().getTime()},
  );

/**
 * @description custom hook that returns the current window pathname and
 * a function to trigger the location change event
 * @return {Object} pathname and onWindowLocationChange
 */
const useWindowPathname = () => {
  const router = useRouter();
  const [pathname, setPathname] = useState(() => router.asPath);

  const onWindowLocationChange = () => {
    if (typeof window !== 'undefined') {
      window.dispatchEvent(new PopStateEvent('popstate'));
    }
  };

  /**
   * @description effect that subscribes to the window location pathname
   * changes
   */
  useEffect(() => {
    const handlePopState = () => {
      setPathname(() => {
        const newPathname = window.location.pathname;
        return newPathname.endsWith('/') ? newPathname : newPathname + '/';
      });
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return {pathname, onWindowLocationChange};
};

const App = ({
  artistSlug,
  artist,
  initSort,
  initCollections,
  initOptions,
  initCount,
  initItems,
  editorsPicks,
  initSelectedCollection,
  initSelectedOptions,
  initSelectedSort,
  initSelectedPage,
}) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const settings = useSelector(selectUserSettingsData);
  const [sort, setSort] = useState(initSort);
  const [collections, setCollections] = useState(initCollections);
  const [options, setOptions] = useState(initOptions);

  const [skipTrackingValue] = useCookie('af-tracking', false);
  const skipTracking = skipTrackingValue === 'disabled';

  const [selectedSort, setSelectedSort] = useState(initSelectedSort);
  const [selectedCollection, setSelectedCollection]: any = useState(initSelectedCollection);
  const [selectedOptions, setSelectedOptions]: any = useState(initSelectedOptions);
  const [page, setPage] = useState(initSelectedPage);
  const [count, setCount] = useState(initCount);
  const [items, setItems]: any = useState(initItems);

  const [loading, setLoding] = useState(false);
  const {pathname, onWindowLocationChange} = useWindowPathname();
  const [, setBasketEmpty] = usePersistBasketStatus();

  const {links: hrefLangLinks} = getAppMetadata({pathname});

  let productArr: any = [];

  const displayingSales = selectedOptions ? selectedOptions.includes(ON_SALE) : false;
  const galleryRef: any = useRef(null);
  const optionsList =
    selectedOptions && selectedOptions.length
      ? selectedOptions.map((el) => '/' + el + '-true').join('')
      : '';
  const canonicalUrlBase = `${process.env.HOST}/artist/${artistSlug}${
    selectedCollection ? '/collections-' + selectedCollection : ''
  }${optionsList}${selectedSort !== DEFAULT_SORT ? '/sort-' + selectedSort : ''}`;

  const state = (_page = page) => ({
    selectedCollection,
    selectedOptions,
    selectedSort,
    page: _page,
  });

  const omitDefaults = _.pipe(
    _.omit(['limit', 'time']),
    _.when(_.pipe(_.prop('sort'), _.equals(DEFAULT_SORT)), _.dissoc('sort')),
    _.when(_.pipe(_.prop('page'), _.equals(DEFAULT_PAGE)), _.dissoc('page')),
  );

  const url = _.pipe(
    state,
    params,
    omitDefaults,
    (x) => _.serialize(x, '/', '-'),
    (x) => prefix(artistSlug) + x,
    trim,
  );

  const setProductInfo = (product) => {
    const currentHeight = window.scrollY + window.innerHeight;
    const productObj = {
      above_fold: currentHeight > product.product_slug,
      product_slug: product.product_slug,
      y_pos: product.y_pos,
    };

    productArr = [...productArr, productObj];
  };

  useEffect(() => {
    get(
      `/api/${artistSlug}/products/`,
      params(state()),
      {
        method: 'OPTIONS',
      },
      skipTracking,
    )
      .then((res) => {
        productArr.length === items.length &&
          trackPage(window.location.pathname, productArr, artist.id);

        dispatch(setArtistId(artist.id));

        setSort(res.sort);
        setCollections(res.collections);
        setOptions(makeOptions(res));
      })
      .catch((err) => {
        console.log('artist products fetch error', err);
      });
  }, [selectedOptions, selectedCollection]);

  useEffect(() => {
    if (
      items === initItems &&
      page === initSelectedPage &&
      selectedOptions === initSelectedOptions &&
      selectedSort === initSelectedSort &&
      selectedCollection === initSelectedCollection
    ) {
      return;
    }
    dispatch(galleryActions({galleryType: 'artist', gallerySlug: artistSlug}).clearSearchResults());
    search();
  }, [selectedSort, selectedCollection, selectedOptions]);

  useEffect(() => {
    if (
      items === initItems &&
      page === initSelectedPage &&
      selectedOptions === initSelectedOptions &&
      selectedSort === initSelectedSort &&
      selectedCollection === initSelectedCollection
    ) {
      return;
    }
    search();
  }, [page]);

  const search = () => {
    let completeUrl = url();
    const queryStringParams = window.location.search.substr(1);
    if (queryStringParams) {
      completeUrl = completeUrl + '?' + queryStringParams;
    }

    window.history.replaceState(undefined, '', completeUrl + window.location.hash);
    onWindowLocationChange();
    historyChangedEvent();
    historyNewPagesChangedEvent();

    setLoding(true);

    const successCallbacks = [
      (data) => {
        setCount(data.count);
        setItems(data.results);
        trackImpressions(data.results);
      },
    ];
    const errorCallbacks = [
      (error) => {
        console.log('artist products fetch error', error);
      },
    ];
    const finalCallbacks = [
      () => {
        setLoding(false);
      },
    ];
    dispatch(
      galleryActions({galleryType: 'artist', gallerySlug: artistSlug}).search(
        params(state()),
        successCallbacks,
        errorCallbacks,
        finalCallbacks,
      ),
    );

    trackPageView();
  };

  useEffect(() => {
    if (!skipTracking) {
      const startIndex = page > 0 ? (page - 1) * LIMIT : 0;
      pushGAViewItemListEvent(items, settings.currency, startIndex);
    }
  }, [items]);

  useEffect(() => {
    if (!skipTracking) {
      gtmData({
        artistId: artist.id,
      });
    }
  }, [items]);

  useEffect(() => {
    dispatch(getCustomerFavourites(artist.id, auth));
  }, [auth]);

  useEffect(() => {
    trackPageView();
  }, []);

  const trackImpressions = (products) => {
    if (!products || !Array.isArray(products)) return;

    const url = `${window.location.pathname + window.location.hash}`;
    const productsToTrack = products.map((product) => ({
      above_fold: true, // Artist shop shows only 12 images per page
      product_slug: product.slug,
      y_pos: 0,
    }));
    dispatch(impressions(url, productsToTrack));
  };

  const trackPageView = () => {
    const url = `${window.location.pathname + window.location.hash}`;
    dispatch(pageView(url));
  };

  const resetAll = () => {
    setPage(DEFAULT_PAGE);
    setSelectedSort(DEFAULT_SORT);
    setSelectedCollection(DEFAULT_COLLECTION);
    setSelectedOptions(DEFAULT_OPTIONS);
  };

  const saleDaysLeft = () => {
    const today = new Date();
    const saleEnds = new Date(artist.sale_end_date);

    const daysLeft = Math.floor(
      (Date.UTC(saleEnds.getFullYear(), saleEnds.getMonth(), saleEnds.getDate()) -
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
        (1000 * 60 * 60 * 24),
    );

    if (daysLeft < 1) {
      return 'today';
    } else if (daysLeft === 1) {
      return 'tomorrow';
    } else {
      return `in ${daysLeft} days`;
    }
  };

  const pageClick = (newPage) => {
    if (newPage === page) return;
    setPage(newPage);
    galleryRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
  };

  const changeOptions = (options) => {
    setPage(DEFAULT_PAGE);
    setSelectedOptions(options);
  };

  const changeCollection = (collection) => {
    setPage(DEFAULT_PAGE);
    setSelectedCollection(collection);
  };

  const changeSorting = (sorting) => {
    setPage(DEFAULT_PAGE);
    setSelectedSort(sorting);
  };

  return (
    <div className="app page-artist">
      <Head>
        <meta
          name="description"
          content={`${artist.name} is an artist based in ${artist.country} with ${
            artist.artworks_for_sale
          } piece${artist.artworks_for_sale > 1 ? 's' : ''} of art available to purchase. Buy ${
            artist.name
          }'s art ${artist.accepts_commissions ? 'or request commission ' : ''}here.`}
        />
        <title>{`Original ${artist.name} - artwork for sale | Artfinder`}</title>
        <meta name="robots" content="index,follow" />
        <meta property="fb:app_id" content={process.env.SOCIAL_AUTH_FACEBOOK_KEY} />
        <meta property="og:image" content={artist.avatar_url} />
        <meta property="og:image:alt" content={`View gallery of ${artist.name}`} />
        <meta property="og:image:width" content="120" />
        <meta property="og:image:height" content="120" />
        <meta property="og:url" content={`${process.env.HOST}/artist/${artistSlug}/`} />
        <meta property="og:site_name" content="Artfinder" />
        <meta name="twitter:image" content={artist.avatar_url} />
        <meta name="twitter:image:alt" content={`View gallery of ${artist.name}`} />
        <meta name="twitter:description" content={`View amazing artworks by ${artist.name}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@artfinder" />
        <link
          rel="canonical"
          href={`${canonicalUrlBase}${page > DEFAULT_PAGE ? '/page-' + page : ''}/`}
        />
        {page > DEFAULT_PAGE && (
          <link
            rel="prev"
            href={`${
              page === DEFAULT_PAGE + 1
                ? canonicalUrlBase
                : canonicalUrlBase + '/page-' + (page - 1)
            }/`}
          />
        )}
        {page < Math.ceil(count / LIMIT) && (
          <link rel="next" href={`${canonicalUrlBase}${'/page-' + (page + 1)}/`} />
        )}

        {hrefLangLinks.map(({id, ...restLinkProps}) => (
          <link key={id} {...restLinkProps} />
        ))}
      </Head>

      <PageLayout editorsPicks={editorsPicks}>
        <Artist auth={auth} artist={artist} />
        <Subnav
          artistSlug={artist.slug}
          active={displayingSales ? 'sale' : 'artworks'}
          hasSale={artist.in_sale}
          hasReviews={artist.review_data.review_count > 0}
          hasMeAtWork={artist.me_at_work}
        />

        <div
          style={{backgroundColor: artist.colour_option_hex || '#7ce0d3'}}
          className={`artwork-by-artist${displayingSales ? ' on-sale' : ''}`}
        >
          <H1 style="main">
            {displayingSales ? 'Sale' : 'Artworks'} by {artist.name}
          </H1>
        </div>

        {artist.in_sale && (
          <>
            <div className="salebar">
              <div className="container">
                <a
                  href={`/artist/${artistSlug}/in_sale-true`}
                  title={`Shop all artworks in sale from ${artist.name}`}
                >
                  <Bell height="22px" width="22px" /> Save {artist.sale_discount_amount}% on
                  selected work. Sale ends {saleDaysLeft()}.
                </a>
              </div>
            </div>
            <hr />
          </>
        )}

        <div className="sortbar" ref={galleryRef}>
          <div className="container">
            <div className="sortbar-inner">
              <div className="sortbar-count">
                <span>
                  {page * LIMIT - LIMIT + 1} - {_.clamp(0, count, page * LIMIT)} of {count} artworks
                </span>
              </div>
              <div className="sortbar-dropdown">
                <DropdownSelect
                  id="ap-sort-products"
                  items={sort.values}
                  value={selectedSort}
                  getValue={_.prop('value')}
                  onChange={changeSorting}
                  alignment="right"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-3 filters-col">
                <FilterSection
                  items={_.prepend({name: 'All Artworks', id: '^'}, collections.values)}
                  selected={selectedCollection}
                  label="Shop section"
                  type="radio"
                  value={_.prop('value')}
                  onChange={changeCollection}
                />
                <FilterSection
                  items={options}
                  selected={selectedOptions}
                  label="Options"
                  type="checkbox"
                  name={optionName}
                  id={_.prop('model')}
                  value={_.prop('model')}
                  onChange={changeOptions}
                />
                <a
                  href="#"
                  style={{margin: '1.25rem 0', display: 'block'}}
                  onClick={(e) => {
                    e.preventDefault();
                    resetAll();
                  }}
                >
                  Reset all filters
                </a>
                {artist.accepts_commissions && (
                  <CtaButton
                    onClick={emitFactory('CLICK_COMMISSION_ARTIST')}
                    style="tertiary"
                    maxWidth="100%"
                    children={`${!auth.isAuthenticated() ? 'New! ' : ''}Commission artist`}
                  />
                )}
              </div>
              <div className="col-md-9">
                {loading && <Loading />}
                {_.isEmpty(items) && !loading && (
                  <H2 style="highlights" align="center">
                    No results found
                  </H2>
                )}
                {!_.isEmpty(items) && (
                  <MasonryGallery>
                    {items.map((x) => {
                      return (
                        <ProductCard
                          product={x}
                          key={x.id}
                          trackingFunc={setProductInfo}
                          onAddToBasket={() => setBasketEmpty(false)}
                        />
                      );
                    })}
                  </MasonryGallery>
                )}
              </div>
            </div>
          </div>
        </div>
        {!_.isEmpty(items) && (
          <Pagination
            pages={Math.ceil(count / LIMIT)}
            active={page}
            onClick={pageClick}
            href={url}
          />
        )}
        <br />
      </PageLayout>

      <ArtistCommission artist={artist} />
      <ArtistContactMe artist={artist} />
    </div>
  );
};

export default App;

// eslint-disable-next-line
export const getServerSideProps = withRedirects(async (context) => {
  const reduxStore = initializeStore({});
  const artistSlug = context.params?.param?.[0] || '';
  try {
    const urlParts = context.resolvedUrl.split('?');
    const pathname = urlParts[0];

    // todo: optimize this (maybe options should be options=a,b or options[]=a&options[]=b)
    // and if queryStringToObject returns somthing like {page:1, options:['a', 'b'], ...}
    // then we can group page, sort, collections and options into one state object
    // the end goal is: setState(queryStringToObject(...));
    const instance = _.queryStringParams(pathname, '/', '-');
    const o = instance.toObject();
    const opts = instance.getWhere((x) => x[1] === 'true').map((x) => x[0]);

    const initSelectedCollection = _.def(o.collections) ? o.collections : DEFAULT_COLLECTION;
    const initSelectedOptions = _.def(opts) ? opts : DEFAULT_OPTIONS;
    const initSelectedSort = _.def(o.sort) ? o.sort : DEFAULT_SORT;
    const initSelectedPage =
      _.def(o.page) && parseInt(o.page) >= 1 ? parseInt(o.page) : DEFAULT_PAGE;

    // Get editors pick from api or from db according to settings
    const {CACHE} = constantsFactory();
    const promises: any[] = [];
    const artistAPICall = get(`/api/artist/${artistSlug}/full/`);
    const productsOptionsAPICall = get(`/api/${artistSlug}/products/`, {}, {method: 'OPTIONS'});
    const productsAPICall = get(
      `/api/${artistSlug}/products/`,
      params({
        selectedCollection: initSelectedCollection,
        selectedOptions: initSelectedOptions,
        selectedSort: initSelectedSort,
        page: initSelectedPage,
      }),
    );

    promises.push(artistAPICall.then((r) => ({name: 'artist', result: r})));
    promises.push(productsOptionsAPICall.then((r) => ({name: 'options', result: r})));
    promises.push(productsAPICall.then((r) => ({name: 'artworks', result: r})));

    let editorsPickFromCache = null;
    try {
      editorsPickFromCache = await cacheStore.get('last_5_editors_pick');
    } catch (e) {
      console.log(`[Artist Shop] Error. editors pick cache get() - ${e}`);
    }

    if (!editorsPickFromCache) {
      console.log(`[Artist Shop] CACHE MISS - editors pick`);
      const editorsPickCall = queryEditorsPick();
      promises.push(editorsPickCall.then((r) => ({name: 'editorsPicks', result: r})));
    }

    const results = await Promise.all(promises.map((p) => p.catch((e) => e)));
    const notFoundResults = results.filter(
      (result) => !(result instanceof Error) && 'detail' in result,
    );

    // return if artist URL is not found
    if (notFoundResults && notFoundResults.length > 0) {
      return {
        notFound: true,
      };
    }

    const artist = results.filter((x) => x.name == 'artist')?.[0]?.result;
    const options = results.filter((x) => x.name == 'options')?.[0]?.result;
    const artworks = results.filter((x) => x.name == 'artworks')?.[0]?.result;
    const editorsPicks =
      editorsPickFromCache ?? results.filter((x) => x.name == 'editorsPicks')?.[0]?.result;

    // set on cache anything we want
    if (!editorsPickFromCache) {
      try {
        await cacheStore.set(
          'last_5_editors_pick',
          editorsPicks,
          CACHE.EDITORS_PICK_CACHE_TTL_MILLISECONDS,
        );
      } catch (e) {
        console.log(`[Artist Shop] Error. editors pick cache set() - ${e}`);
      }
    }

    // Set cache headers
    context.res.setHeader(
      'Cache-Control',
      `public, s-maxage=${CACHE.ARTIST_CACHE_TTL_SECONDS}, stale-while-revalidate=${CACHE.ARTIST_CACHE_STALE_WHILE_REVALIDATE_TTL_SECONDS}`,
    );

    return {
      props: {
        artistSlug,
        artist: artist,
        initSort: options.sort,
        initCollections: options.collections,
        initOptions: makeOptions(options),
        initCount: artworks.count,
        initItems: artworks.results,
        editorsPicks: editorsPicks,
        initSelectedCollection,
        initSelectedOptions,
        initSelectedSort,
        initSelectedPage,
        initialReduxState: reduxStore.getState(),
      },
    };
  } catch (error) {
    console.log(`[Artist Shop] Error. Invalid Rendering - page: [${context.resolvedUrl}]`);
    Bugsnag.notify(
      new Error(`[Artist Shop] Error. Invalid Rendering - page: [${context.resolvedUrl}]`),
      (event) => {
        event.severity = 'error';
        event.unhandled = true;
      },
    );

    // TODO: sending 404 not found because returning the error is causing infinite loop
    return {
      notFound: true,
    };
  }
});
